import React from "react";
import Navbar from "components/Navbar";

const DefaultPageContainer = (WrappedComponent: React.FC): React.FC => {
  return (props: any) => {
    return (
      <div className="default-container">
        <Navbar />
        <WrappedComponent />
      </div>
    );
  };
};

export default DefaultPageContainer;
