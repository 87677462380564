import * as React from "react";
import DefaultPageContainer from "containers/DefaultPageContainer";

const Home: React.FC = () => {
  return (
    <div className="home-container">
      <p>
        Hi, how are you?
        <br />
        <br />
        This will be my hobby project which will present the information on the
        <span className="text-success"> politicians from Nepal.</span>
        <br />
        <br />
        The user who will be opening this site will be given the opportunity to
        discuss on the forum created for each posts.
        <br />
        <br />
        The user will also have say in the editing of the content similar to
        wikipedia. But, of course we will be monitoring and verifying the
        change.
        <br />
        <br />I want to build this platform as a community, hope you guys will
        love it.
      </p>
    </div>
  );
};

export default DefaultPageContainer(Home);
