import React from "react";

const Navbar: React.FC = () => {
  return (
    <div className="navbar">
      <a className="navbar-logo" href="/">
        <img src="/assets/logo-default.png" className="navbar-logo-img" />
        <span className="navbar-logo-text">Neta ko katha</span>
      </a>
      <div className="navbar-items">
        {/* <ul>
          <li>
            <span>Home</span>
          </li>
          <li>
            <span>Services</span>
          </li>
        </ul> */}
      </div>
    </div>
  );
};

export default Navbar;
